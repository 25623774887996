<template>
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V14.0016H24V0Z" fill="white"/>
    <path d="M16.0686 5.0625L23.9998 0.61875V0H23.4514L14.4092 5.0625H16.0686Z" fill="#BD0034"/>
    <path d="M15.6938 9.22029L24.0001 13.8656V12.9375L17.3532 9.22029H15.6938Z" fill="#BD0034"/>
    <path d="M0 1.06408L7.10156 5.06252H8.76562L0 0.131271V1.06408Z" fill="#BD0034"/>
    <path d="M8.38594 9.22029L0 13.9172V14.0015H1.51406L10.05 9.22029H8.38594Z" fill="#BD0034"/>
    <path d="M22.3593 0H13.9546V4.70625L22.3593 0Z" fill="#1A237B"/>
    <path d="M10.2422 0H1.875L10.2422 4.70625V0Z" fill="#1A237B"/>
    <path d="M23.9998 5.06249V1.84686L18.2998 5.06249H23.9998Z" fill="#1A237B"/>
    <path d="M23.9998 12.3937V9.22029H18.2998L23.9998 12.3937Z" fill="#1A237B"/>
    <path d="M2.34863 14.0015H10.2424V9.57185L2.34863 14.0015Z" fill="#1A237B"/>
    <path d="M13.9546 14.0015H21.8718L13.9546 9.57185V14.0015Z" fill="#1A237B"/>
    <path d="M0 9.22029V12.539L5.89688 9.22029H0Z" fill="#1A237B"/>
    <path d="M0 5.0625H5.89688L0 1.73438V5.0625Z" fill="#1A237B"/>
    <path d="M10.9875 0V5.89219H0V8.38594H10.9875V14.0016H13.2141V8.38594H24V5.89219H13.2141V0H10.9875Z" fill="#BD0034"/>
  </svg>
</template>

<script>
export default {
  name: 'En'
}
</script>
